import React from 'react';

const FilledBeaker = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <g opacity="0.5">
      <path
        d="M5.625 5.31066V1.875H9.375V5.31066C9.375 5.91622 9.59479 6.50119 9.99356 6.95692L13.4128 10.8646C13.6302 11.1131 13.75 11.432 13.75 11.7621C13.75 12.5148 13.1398 13.125 12.3871 13.125H2.61291C1.86019 13.125 1.25 12.5148 1.25 11.7621C1.25 11.432 1.36982 11.1131 1.58721 10.8646L5.00644 6.95692C5.4052 6.50119 5.625 5.91622 5.625 5.31066Z"
        fill="#363C49"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 1.875C5 1.52982 5.27982 1.25 5.625 1.25H9.375C9.72018 1.25 10 1.52982 10 1.875V5.31066C10 5.76483 10.1648 6.20356 10.4639 6.54536L13.8831 10.453C14.2002 10.8154 14.375 11.2806 14.375 11.7621C14.375 12.86 13.485 13.75 12.3871 13.75H2.61291C1.51502 13.75 0.625 12.86 0.625 11.7621C0.625 11.2806 0.799773 10.8154 1.11685 10.453L4.53608 6.54536C4.83515 6.20356 5 5.76483 5 5.31066V1.875ZM6.25 2.5V5.31066C6.25 6.06761 5.97526 6.79883 5.4768 7.36849L2.05757 11.2762C1.93988 11.4107 1.875 11.5834 1.875 11.7621C1.875 12.1696 2.20537 12.5 2.61291 12.5H12.3871C12.7946 12.5 13.125 12.1696 13.125 11.7621C13.125 11.5834 13.0601 11.4107 12.9424 11.2762L9.5232 7.36849C9.02474 6.79883 8.75 6.06761 8.75 5.31066V2.5H6.25Z"
        fill="#363C49"
      />
      <path
        d="M3.75 1.875C3.75 1.52982 4.02982 1.25 4.375 1.25H10.625C10.9702 1.25 11.25 1.52982 11.25 1.875C11.25 2.22018 10.9702 2.5 10.625 2.5H4.375C4.02982 2.5 3.75 2.22018 3.75 1.875Z"
        fill="#363C49"
      />
    </g>
  </svg>
);

export default FilledBeaker;
