import React from 'react';

const Share = (props) => (
  <svg viewBox="0 0 25 20" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.4077 0.0908266C13.7633 -0.0720282 14.1812 -0.0126254 14.4773 0.24287L24.2978 8.71571C24.5102 8.89903 24.6359 9.16318 24.6441 9.44368C24.6523 9.72417 24.5422 9.99521 24.3408 10.1906L14.5204 19.7178C14.2321 19.9975 13.8044 20.0775 13.4344 19.921C13.0645 19.7644 12.8241 19.4017 12.8241 19V14.0701C6.59723 14.3461 3.38665 17.1819 2.58195 19.3482C2.41339 19.802 1.94405 20.0699 1.46763 19.9842C0.991219 19.8986 0.644531 19.4841 0.644531 19C0.644531 13.2325 2.57688 9.86338 5.36111 8.00158C7.71101 6.43021 10.518 6.04171 12.8241 5.96713V1.00001C12.8241 0.608899 13.0521 0.253681 13.4077 0.0908266ZM14.8241 3.18354V6.95183C14.8241 7.50411 14.3764 7.95183 13.8241 7.95183C11.4322 7.95183 8.64893 8.20898 6.47284 9.66412C4.90082 10.7153 3.5315 12.4737 2.94652 15.5193C5.18474 13.532 8.84601 12.0482 13.8241 12.0482C14.3764 12.0482 14.8241 12.4959 14.8241 13.0482V16.6366L22.1638 9.51609L14.8241 3.18354Z"
    />
  </svg>
);

export default Share;
