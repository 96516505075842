import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';
import FocusTrap from 'focus-trap-react';
import Icon from '../Icon';
import './styles.scss';

class Modal extends React.Component {
  constructor() {
    super();

    this.state = {
      open: false,
    };
  }

  componentWillUnmount() {
    document.body.classList.remove('modal-open');
    document.body.style.paddingRight = '';
  }

  getScrollbarWidth = () => {
    const scrollDiv = document.createElement('div');
    scrollDiv.className = 'modal__scrollbar-measure';
    document.body.appendChild(scrollDiv);
    const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    document.body.removeChild(scrollDiv);
    return scrollbarWidth;
  };

  handleTriggerClick = (e) => {
    const { trigger } = this.props;
    this.setState({ open: true }, () => {
      this.closeButton.focus();
    });
    if (trigger.props.onClick) {
      trigger.props.onClick(e);
    }
    document.body.classList.add('modal-open');
    const scrollbarWidth = this.getScrollbarWidth();
    document.body.style.paddingRight = `${scrollbarWidth}px`;
  };

  handleContainerClick = (e) => {
    if (e.target === this.modalContainer) {
      this.handleCloseClick();
    }
  };

  handleCloseClick = () => {
    this.setState({
      open: false,
    });
    document.body.classList.remove('modal-open');
    document.body.style.paddingRight = '';
  };

  handleCloseKey = (e) => {
    if (e.keyCode === 27) {
      this.handleCloseClick();
    }
  };

  renderModal = () => {
    const { heading, children, video, image } = this.props;
    return (
      <FocusTrap>
        <div
          className="modal"
          role="dialog"
          aria-modal="true"
          aria-labelledby={heading ? 'dialog-label' : null}
          tabIndex="-1"
          onKeyDown={this.handleCloseKey}
        >
          <div className="modal__overlay" />

          <div
            className="modal__container"
            onClick={this.handleContainerClick}
            onKeyDown={this.handleClick}
            ref={(c) => {
              this.modalContainer = c;
            }}
          >
            <div
              className={classNames(
                'modal__content',
                video && 'modal__content--with-video',
                image && 'modal__content--with-image'
              )}
            >
              {' '}
              <button
                aria-label="Close Modal"
                onClick={this.handleCloseClick}
                onKeyDown={this.handleClick}
                className="modal__close-btn"
                ref={(c) => {
                  this.closeButton = c;
                }}
              >
                <Icon name="close" className="modal__close-btn-icon" />
              </button>
              {heading && <h2 id="dialog-label">{heading}</h2>}
              {children}
            </div>
          </div>
        </div>
      </FocusTrap>
    );
  };

  render() {
    const { open } = this.state;
    const { trigger } = this.props;

    return (
      <>
        {React.cloneElement(trigger, {
          onClick: this.handleTriggerClick,
          ref: (t) => {
            this.trigger = t;
          },
        })}
        <CSSTransition
          in={open}
          timeout={350}
          classNames="modal-"
          unmountOnExit
        >
          <>
            {typeof document !== 'undefined' &&
              ReactDOM.createPortal(this.renderModal(), document.body)}
          </>
        </CSSTransition>
      </>
    );
  }
}

Modal.defaultProps = {
  video: false,
};

Modal.propTypes = {
  /** A heading for the modal */
  heading: PropTypes.string,
  /** The contents of the modal */
  children: PropTypes.node.isRequired,
  /** The element that triggers the modal to open when clicked */
  trigger: PropTypes.element.isRequired,
  /** A boolean that, when true, will adjust the modal's width and padding to be more suitable for a video embed */
  video: PropTypes.bool,
  /** A boolean that, when true, will adjust the modal's width and padding to be more suitable for an image*/
  image: PropTypes.bool,
};

export default Modal;
