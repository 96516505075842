import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Container, Row, Column } from '../Grid';
import Subscribe from '../Subscribe';
import OutboundLink from '../OutboundLink';
import Image from '../Image';
import Button from '../Button';
import Icon from '../Icon';
import './styles.scss';

/**
 * A global footer component that is populated with properties from `gatsby-config.js`
 */

const Footer = () => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          partnerName
          partnerLogo
          partnerUrl
          programName
          programHashtag
          aboutProgram
          menuLinks {
            label
            path
          }
        }
      }
    }
  `);

  const handleBackToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <footer className="footer">
      <div className="footer__top">
        <Container>
          <div className="footer__to_top">
            <button className="footer__to_top-button" onClick={handleBackToTop}>
              Back to Top
              <span className="footer__to_top-icon">
                <Icon name="arrowup" />
              </span>
            </button>
          </div>
          <div>
            <Row>
              <Column size={5}>
                <h2 className="footer__heading">About the Program</h2>
                <div className="footer__about">
                  <p>{site.siteMetadata.aboutProgram}</p>
                  <Button to="/about">Learn more</Button>
                </div>
              </Column>
              <Column size={6} offset={1}>
                <h2 className="footer__heading">Stay Connected</h2>
                <p>
                  Stay up to date on latest curriculum additions and educational
                  materials.
                </p>
                <div className="footer__updates">
                  <Subscribe trigger={<Button>Sign up for updates</Button>} />
                  {/* TODO: Add share back in once we have updated content for this. */}
                  {/* <div className="footer__share">
                    <Share inDropDown={false} className="pt-2" />
                    <p className="pt-0.5">{site.siteMetadata.programHashtag}</p>
                  </div> */}
                </div>
              </Column>
            </Row>
            <Row></Row>
          </div>
        </Container>
      </div>
      <div className="footer__lower-section">
        <Container className="footer__lower">
          <Image filename="logo-genentech.svg" className="footer__lower-logo" />
          <div className="footer__base-links mb-1 pt-1">
            <ul className="footer__base-list">
              <li className="footer__base-list-item">
                <OutboundLink
                  to="/"
                  className="cookie_settings footer__base-link"
                >
                  Cookie Settings
                </OutboundLink>
              </li>
              <li className="footer__base-list-item">
                <OutboundLink
                  to="https://www.discoveryeducation.com/privacy-policy/"
                  className="footer__base-link"
                >
                  Privacy Policy
                </OutboundLink>
              </li>
              <li className="footer__base-list-item">
                <OutboundLink
                  to="https://www.discoveryeducation.com/terms-of-use/"
                  className="footer__base-link"
                >
                  Terms and Conditions
                </OutboundLink>
              </li>
              <li className="footer__base-list-item mr-2">
                <OutboundLink
                  to="mailto:futurelabplus@discoveryed.com"
                  className="footer__base-link"
                >
                  Contact Us
                </OutboundLink>
              </li>
            </ul>
            <div className="footer__copyright pl-0.5 pt-0.5">
              © {site.siteMetadata.partnerName} All Rights Reserved.
            </div>
          </div>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
